import React from 'react';


type Props = {
}

const About: React.FC<Props> = ({ }) => {
  return (
    <div>
      About
    </div>
  )
}

export default About